import { createEffect, createMemo, For, onMount, Setter, Show } from "solid-js";
import { editAICircularIcon } from "~/assets/assets";
import { createSignal } from "solid-js";
import { GiftCardDetails, GiftCardSelection } from "~/server/types/pluto";
import { hubbleBrandingFooter } from "./gift_card_front";

export function GiftCardBack({
  giftDetails,
  isEditable,
  onClick,
  onUpdate,
}: {
  giftDetails: () => GiftCardDetails;
  isEditable?: boolean;
  onClick?: () => void;
  onUpdate?: Setter<GiftCardSelection>;
}) {
  const [currentImageIndex, setCurrentImageIndex] = createSignal(0);

  const latestTitleIndex = createMemo(() => {
    return Number(giftDetails().content.selection.titleId) - 1;
  });

  const [showTitleDotsIndex, setShowTitleDotsIndex] = createSignal<
    number | null
  >(null);

  const latestActivityIndices = createMemo(() => {
    return Array(giftDetails()?.content.text.activitySlots.length)
      .fill(0)
      .map((_, index) => {
        return (
          Number(giftDetails()?.content.selection.activities[index + 1] || 1) -
          1
        );
      });
  });

  const [titleIndex, setTitleIndex] = createSignal(
    Number(giftDetails().content.selection.titleId) - 1
  );

  const activitySlots = giftDetails()?.content.text.activitySlots || [];

  const activitySelections = giftDetails()?.content.selection.activities || {};

  const initialActivityIndices = Array(activitySlots.length)
    .fill(0)
    .map((_, index) => {
      return Number(activitySelections[index + 1] || 1) - 1;
    });

  const [activityIndices, setActivityIndices] = createSignal(
    initialActivityIndices
  );

  const [titleFade, setTitleFade] = createSignal(false);
  const [activityFades, setActivityFades] = createSignal(
    Array(activitySlots.length).fill(false)
  );
  const [imageFade, setImageFade] = createSignal(false);

  const [showDotsIndex, setShowDotsIndex] = createSignal<number | null>(null);
  const [showImageDotsIndex, setShowImageDotsIndex] = createSignal<
    number | null
  >(null);

  let titleDotsTimeout: ReturnType<typeof setTimeout> | undefined;
  const dotsTimeouts: (ReturnType<typeof setTimeout> | undefined)[] = [];
  let imageDotsTimeout: ReturnType<typeof setTimeout> | undefined;

  const cycleActivityImage = () => {
    const images = giftDetails()?.content.images.activityImages || [];
    setImageFade(true);
    setShowImageDotsIndex(0);

    if (imageDotsTimeout) clearTimeout(imageDotsTimeout);

    imageDotsTimeout = setTimeout(() => setShowImageDotsIndex(null), 2000);

    setTimeout(() => {
      const nextIndex = (currentImageIndex() + 1) % images.length;
      setCurrentImageIndex(nextIndex);

      onUpdate?.((prev) => ({
        ...prev,
        activityImageId: images[nextIndex].id,
      }));

      setImageFade(false);
    }, 250);
  };

  const rotateTitle = () => {
    setTitleFade(true);
    setShowTitleDotsIndex(0);

    if (titleDotsTimeout) clearTimeout(titleDotsTimeout);

    titleDotsTimeout = setTimeout(() => setShowTitleDotsIndex(null), 2000);

    setTimeout(() => {
      const titles = giftDetails()?.content.text.titles;
      if (titles) {
        const nextIndex = (titleIndex() + 1) % titles.length;
        setTitleIndex(nextIndex);

        const newTitleId = titles[nextIndex].id;
        onUpdate?.((prev) => ({
          ...prev,
          titleId: newTitleId,
        }));
      }
      setTitleFade(false);
    }, 250);
  };

  const rotateActivity = (index: number) => {
    setActivityFades((prevFades) => {
      const newFades = [...prevFades];
      newFades[index] = true;
      return newFades;
    });

    setShowDotsIndex(index);

    if (dotsTimeouts[index]) clearTimeout(dotsTimeouts[index]);

    dotsTimeouts[index] = setTimeout(() => {
      if (showDotsIndex() === index) {
        setShowDotsIndex(null);
      }
    }, 2000);

    setTimeout(() => {
      setActivityIndices((prevIndices) => {
        const newIndices = [...prevIndices];
        const activityDescriptions = activitySlots[index]?.activities;
        if (activityDescriptions) {
          const nextIndex =
            (newIndices[index] + 1) % activityDescriptions.length;
          newIndices[index] = nextIndex;

          const slotId = activitySlots[index].id;
          const newActivityId = activityDescriptions[nextIndex].id;

          onUpdate?.((prev) => ({
            ...prev,
            activities: {
              ...prev.activities,
              [slotId]: newActivityId,
            },
          }));
        }
        return newIndices;
      });

      setActivityFades((prevFades) => {
        const newFades = [...prevFades];
        newFades[index] = false;
        return newFades;
      });
    }, 250);
  };

  createEffect(() => {
    setTitleIndex(latestTitleIndex());
    if (!isEditable) {
      if (
        JSON.stringify(latestActivityIndices()) !==
        JSON.stringify(activityIndices())
      ) {
        setActivityIndices(latestActivityIndices());
      }
    }

    const currentId = giftDetails().content.selection.activityImageId;
    const images = giftDetails().content.images.activityImages;
    const index = images.findIndex((img) => img.id === currentId);
    setCurrentImageIndex(index >= 0 ? index : 0);
  });

  let giftCardBackContentRef: HTMLDivElement | undefined = undefined;
  let giftCardBackContainerRef: HTMLDivElement | undefined = undefined;

  const [imageHeight, setImageHeight] = createSignal(0);

  onMount(() => {
    setImageHeight(
      giftCardBackContainerRef!.offsetHeight -
        giftCardBackContentRef!.offsetHeight
    );
  });

  function buildImage() {
    return (
      <div
        class={`relative flex h-full w-full flex-col items-center justify-center ${isEditable ? "overflow-visible" : "overflow-x-hidden overflow-y-hidden"}`}
        classList={{
          [`h-[${imageHeight()}px]`]: !!imageHeight(),
          "relative rounded-[18px] border border-[#ffffff33] p-2": isEditable,
        }}
      >
        <Show when={isEditable}>
          <div class="absolute -right-[6px] -top-[6px]">
            <img
              onClick={(e) => {
                e.stopPropagation();
                cycleActivityImage();
              }}
              src={editAICircularIcon}
              class="relative z-[1]"
              alt="edit"
            />
            <Show when={showImageDotsIndex() !== null}>
              <div
                class={`absolute -top-[7px] left-[5px]  h-1.5 w-1.5 rounded-full border border-white ${
                  currentImageIndex() % 3 === 1 ? "bg-white" : ""
                }`}
              ></div>
              <div
                class={`absolute -left-[3px] -top-[2px] h-1.5 w-1.5 rounded-full border border-white ${
                  currentImageIndex() % 3 === 0 ? "bg-white" : ""
                }`}
              ></div>
            </Show>
          </div>
        </Show>
        <img
          onClick={(e) => {
            if (isEditable) {
              e.stopPropagation();
              cycleActivityImage();
            }
          }}
          src={
            giftDetails()?.content.images.activityImages[currentImageIndex()]
              .imageUrl
          }
          class="h-full w-full   rounded-xl object-cover transition-opacity duration-500 "
          classList={{
            "opacity-0": imageFade(),
          }}
          alt=""
        />
      </div>
    );
  }

  function buildContent() {
    return (
      <div
        ref={giftCardBackContentRef}
        class="flex h-max w-full flex-col items-start justify-start "
      >
        <div
          class={`my-4 w-full  ${isEditable ? "relative rounded-2xl border border-[#ffffff33] p-2" : ""}`}
          onClick={() => {
            if (isEditable) {
              rotateTitle();
            }
          }}
        >
          <Show when={isEditable}>
            <div class="absolute -right-[6px] -top-[6px]">
              <img src={editAICircularIcon} alt="edit" class="relative" />
              <Show when={showTitleDotsIndex() !== null}>
                <div
                  class={`absolute -top-[8px] left-[8px] h-1.5 w-1.5 rounded-full border border-white ${
                    titleIndex() % 3 === 2 ? "bg-white" : ""
                  }`}
                ></div>
                <div
                  class={`absolute -top-[5px] left-0 h-1.5 w-1.5 rounded-full border border-white ${
                    titleIndex() % 3 === 1 ? "bg-white" : ""
                  }`}
                ></div>
                <div
                  class={`absolute -left-[6px] top-[2px] h-1.5 w-1.5 rounded-full border border-white ${
                    titleIndex() % 3 === 0 ? "bg-white" : ""
                  }`}
                ></div>
              </Show>
            </div>
          </Show>
          <p
            class={`relative text-start font-jakartaSans text-h3 text-baseTertiaryLight transition-opacity duration-500 ${
              titleFade() ? "opacity-0" : "opacity-100"
            }`}
          >
            {giftDetails()?.content.text.titles[titleIndex()]?.title}
          </p>
        </div>
        <div class="mb-5 flex w-full flex-row items-center justify-start gap-1.5">
          <p class=" flex w-max max-w-[80%] text-wrap    text-[10px] font-medium uppercase text-[#A591A4]">
            Thoughtfully Created by {giftDetails()?.senderName}
          </p>
          <hr class="flex  w-full flex-1  border-t border-[#A591A4]" />
        </div>
        <div class="flex w-full flex-col gap-2.5">
          <For each={activitySlots}>
            {(activity, index) => {
              return (
                <div
                  class={`flex items-center gap-2.5 ${
                    isEditable
                      ? "relative rounded-2xl border border-[#ffffff33] p-2"
                      : ""
                  }`}
                  onClick={() => {
                    if (isEditable) {
                      rotateActivity(index());
                    }
                  }}
                >
                  <Show when={isEditable}>
                    <div class="absolute -right-[6px] -top-[6px]">
                      <img
                        src={editAICircularIcon}
                        alt="edit"
                        class="relative"
                      />
                      <Show when={showDotsIndex() === index()}>
                        <div
                          class={`absolute -top-[8px] left-[8px] h-1.5 w-1.5 rounded-full border border-white ${
                            activityIndices()[index()] % 3 === 2
                              ? "bg-white"
                              : ""
                          }`}
                        ></div>
                        <div
                          class={`absolute -top-[5px] left-0 h-1.5 w-1.5 rounded-full border border-white ${
                            activityIndices()[index()] % 3 === 1
                              ? "bg-white"
                              : ""
                          }`}
                        ></div>
                        <div
                          class={`absolute -left-[6px] top-[2px] h-1.5 w-1.5 rounded-full border border-white ${
                            activityIndices()[index()] % 3 === 0
                              ? "bg-white"
                              : ""
                          }`}
                        ></div>
                      </Show>
                    </div>
                  </Show>
                  <img
                    src={
                      giftDetails().brandDetails.find(
                        (brand) =>
                          brand.brandKey ===
                          activity.activities[activityIndices()[index()]]
                            .brandKey
                      )?.logoUrl
                    }
                    alt=""
                    class="aspect-square h-10 w-10 rounded-full object-cover"
                  />
                  <p
                    class={`relative text-left text-medium text-white transition-opacity duration-500 ${
                      activityFades()[index()] ? "opacity-0" : "opacity-100"
                    }`}
                  >
                    {
                      activity.activities[activityIndices()[index()]]
                        ?.description
                    }
                  </p>
                </div>
              );
            }}
          </For>
        </div>
        <div class="mt-4"></div>
        <Show when={!isEditable}>{hubbleBrandingFooter()}</Show>
      </div>
    );
  }

  return (
    <div
      onClick={onClick}
      ref={giftCardBackContainerRef}
      class="flex h-full w-full flex-col items-center justify-start  px-4 pb-2 pt-4"
    >
      {buildImage()}
      {buildContent()}
    </div>
  );
}
