import {
  editAICircularIcon,
  hubbleLogoPurple,
  plutoLogo,
} from "~/assets/assets";
import { EditableField } from "./editable_field";
import { createEffect, createSignal, JSX, onMount, Show } from "solid-js";
import { CloseModalButton, useModal } from "../../modal";
import { GiftCardDetails } from "~/server/types/pluto";
import { GiftCardOccasionWithImage } from "~/routes/pluto/create/[giftId]/edit";

export function GiftCardFront({
  giftDetails,
  isEditable,
  onClick,
  onUpdate,
}: {
  giftDetails: () => GiftCardDetails;
  isEditable?: boolean;
  onClick?: () => void;
  onUpdate?: (updatedTextFields: GiftCardOccasionWithImage) => void;
}) {
  const { openModal, closeModal } = useModal()!;

  const maxCharLimits = {
    recipientName: 100,
    occasionTitle: 100,
    wishMessage: 500,
  };

  const [textFields, setTextFields] = createSignal({
    recipientName: giftDetails()?.content.text.occasion.recipientName || "",
    occasionTitle: giftDetails()?.content.text.occasion.occasionTitle || "",
    wishMessage: giftDetails()?.content.text.occasion.wishMessage || "",
  });

  const [currentImageIndex, setCurrentImageIndex] = createSignal(0);
  const [imageFade, setImageFade] = createSignal(false);

  const cycleOccasionImage = () => {
    const images = giftDetails()?.content.images.occasionImages || [];
    setImageFade(true);

    setTimeout(() => {
      const nextIndex = (currentImageIndex() + 1) % images.length;
      setCurrentImageIndex(nextIndex);

      onUpdate?.({
        ...textFields(),
        occasionImageId: images[nextIndex].id,
      });
      setImageFade(false);
    }, 250);
  };

  const editUserContent = (
    field: keyof ReturnType<typeof textFields>,
    title: string
  ) => {
    const [tempText, setTempText] = createSignal(textFields()[field]);
    let textareaRef: HTMLTextAreaElement | undefined;

    const handleInputChange: JSX.EventHandler<
      HTMLTextAreaElement,
      InputEvent
    > = (e) => {
      const textarea = e.currentTarget;
      adjustTextareaHeight(textarea);
      setTempText(textarea.value);
    };

    const adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
      textarea.style.height = "auto";
      const newHeight = Math.min(textarea.scrollHeight, 168);
      textarea.style.height = `${newHeight}px`;
    };

    const updateContent = () => {
      setTextFields((prev) => ({
        ...prev,
        [field]: tempText(),
      }));
      onUpdate?.({ ...textFields(), [field]: tempText() });
      closeModal();
    };

    setTimeout(() => {
      if (textareaRef) adjustTextareaHeight(textareaRef);
    });

    openModal(
      () => {
        onMount(() => {
          if (textareaRef) {
            textareaRef.focus();
          }
        });

        return (
          <div class="flex w-full flex-col gap-3 p-4">
            <div class="flex justify-between">
              <p class="text-h5 text-white">{title}</p>
              <CloseModalButton />
            </div>
            <div>
              <textarea
                ref={textareaRef}
                rows="1"
                value={tempText()}
                onInput={handleInputChange}
                maxlength={maxCharLimits[field]}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (e.shiftKey) {
                      e.preventDefault();
                      const textarea = e.currentTarget;
                      const start = textarea.selectionStart;
                      const end = textarea.selectionEnd;
                      const value = textarea.value;

                      const updatedText =
                        value.substring(0, start) + "\n" + value.substring(end);

                      if (updatedText.length <= maxCharLimits[field]) {
                        setTempText(updatedText);

                        requestAnimationFrame(() => {
                          textarea.setSelectionRange(start + 1, start + 1);
                        });
                      }
                    } else {
                      e.preventDefault();
                      const trimmedText = tempText().trim();

                      if (
                        trimmedText &&
                        trimmedText.length <= maxCharLimits[field]
                      ) {
                        updateContent();
                      }
                    }
                  }
                }}
                class="text-sm no-scrollbar block max-h-40 w-full resize-none overflow-y-auto rounded-xl border border-basePrimaryLight bg-black p-3 pe-16 pl-5 text-white placeholder:text-baseSecondaryDark focus:border-baseTertiaryLight focus:ring-baseTertiaryLight disabled:pointer-events-none"
                placeholder={title}
              />
              <div
                class={`mt-0.5 flex items-center ${!tempText().trim() ? "justify-between" : "justify-end"}`}
              >
                <Show when={!tempText().trim()}>
                  <p class="text-[10px] font-semibold text-errorDark">
                    Please add a {title}
                  </p>
                </Show>
                <div class="self-end text-[10px] font-semibold text-textNormal">
                  {tempText().length}/{maxCharLimits[field]}
                </div>
              </div>
            </div>
            <button
              class="flex h-11 w-full items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3 disabled:pointer-events-none disabled:opacity-50"
              disabled={!tempText().trim()}
              onClick={updateContent}
            >
              <p class="text-buttonMedium font-bold text-textDark">Done</p>
            </button>
          </div>
        );
      },
      "dark lg:w-[400px]",
      "!bg-black !border-t !border-basePrimaryDark"
    );
  };

  createEffect(() => {
    if (giftDetails()) {
      setTextFields({
        recipientName: giftDetails().content.text.occasion.recipientName || "",
        occasionTitle: giftDetails().content.text.occasion.occasionTitle || "",
        wishMessage: giftDetails().content.text.occasion.wishMessage || "",
      });

      const currentId = giftDetails().content.selection.occasionImageId;
      const images = giftDetails().content.images.occasionImages;
      const index = images.findIndex((img) => img.id === currentId);
      setCurrentImageIndex(index >= 0 ? index : 0);
    }
  });

  let giftCardFrontContentRef: HTMLDivElement | undefined = undefined;
  let giftCardFrontContainerRef: HTMLDivElement | undefined = undefined;

  const [imageHeight, setImageHeight] = createSignal(0);

  onMount(() => {
    setImageHeight(
      giftCardFrontContainerRef!.offsetHeight -
        giftCardFrontContentRef!.offsetHeight
    );
  });

  function isMessageLong() {
    return textFields().wishMessage.length > 150;
  }

  return (
    <>
      <div
        onClick={onClick}
        ref={giftCardFrontContainerRef}
        class="flex  h-full flex-col items-center justify-start  px-4 pb-2 pt-4"
      >
        {buildImage()}
        <div
          ref={giftCardFrontContentRef}
          class={`flex h-min flex-col  text-center text-white `}
        >
          <div class="mt-5"></div>
          <Show
            when={isEditable}
            fallback={buildContent({
              showFullTitle: false,
              showFullMessage: !isMessageLong(),
            })}
          >
            <>
              <EditableField
                value={() => textFields().recipientName}
                classNames="text-medium"
                onClick={() =>
                  editUserContent("recipientName", "Receiver's name")
                }
              />
              <div class="mt-3"></div>
              <EditableField
                value={() => textFields().occasionTitle}
                classNames="text-h3"
                onClick={() => editUserContent("occasionTitle", "Edit Message")}
              />
              <div class="mt-3"></div>
              <EditableField
                value={() => textFields().wishMessage}
                classNames="text-medium text-[#C6A6CC]"
                onClick={() => editUserContent("wishMessage", "Wish message")}
              />
            </>
          </Show>
          <Show when={!isEditable}>{hubbleBrandingFooter()}</Show>
        </div>
      </div>
    </>
  );

  function buildViewFullMessageCta() {
    const { setIsModalOn, updateModalContentGetter, setInnerFrameClass } =
      useModal()!;

    return (
      <span
        onClick={(e) => {
          e.stopPropagation();
          setInnerFrameClass("!p-0  !border-0");

          updateModalContentGetter(() => {
            return (
              <div
                class="flex flex-col items-center justify-start p-4 pb-6 text-white"
                style={{
                  background:
                    "linear-gradient(180deg, #33243D 0%, #462C49 100%)",
                }}
              >
                <div class="dark absolute right-4 top-4">
                  <CloseModalButton />
                </div>
                <div class="mt-5"></div>
                {buildContent({
                  showFullTitle: true,
                  showFullMessage: true,
                  paddingAboveSenderNameClass: "!h-7",
                })}
              </div>
            );
          });
          setIsModalOn(true);
        }}
        class="cursor-pointer text-[11px] font-semibold uppercase tracking-[1.1px] text-white"
      >
        <u>View full message</u>
      </span>
    );
  }
  function buildContent(props: {
    showFullTitle: boolean;
    showFullMessage: boolean;
    paddingAboveSenderNameClass?: string;
  }) {
    return (
      <>
        <p class="text-medium">{textFields().recipientName}</p>
        <div class="h-1"></div>
        <h1
          class="overflow-hidden text-center font-jakartaSans text-h3"
          classList={{
            "line-clamp-2 text-ellipsis": !props.showFullTitle,
          }}
        >
          {textFields().occasionTitle}
        </h1>
        <div class="h-3"></div>

        <p
          class="overflow-hidden text-ellipsis text-center text-medium text-[#C6A6CC]"
          classList={{
            "line-clamp-3": !props.showFullMessage,
          }}
        >
          {textFields().wishMessage}
        </p>
        <div
          class="h-2"
          classList={{
            [props.paddingAboveSenderNameClass || ""]:
              !!props.paddingAboveSenderNameClass,
          }}
        ></div>

        <Show when={props.showFullMessage} fallback={buildViewFullMessageCta()}>
          <p class="line-clamp-1 overflow-hidden text-ellipsis text-medium text-[#C6A6CC]">
            - {giftDetails().senderName}
          </p>
        </Show>
        <div class="h-[14px]"></div>
      </>
    );
  }

  function buildImage() {
    return (
      <>
        <div
          class={`relative flex h-full w-full flex-col items-center justify-center rounded-[10px] ${isEditable ? "overflow-visible" : "overflow-x-hidden overflow-y-hidden"}`}
          classList={{
            "rounded-[18px] p-2  border border-[#ffffff33]": isEditable,
            [`h-[${imageHeight()}px]`]: !!imageHeight(),
          }}
        >
          <Show when={isEditable}>
            <img
              onClick={(e) => {
                e.stopPropagation();
                cycleOccasionImage();
              }}
              src={editAICircularIcon}
              class="absolute -right-[6px] -top-[6px] z-[1] flex w-min"
              alt="edit"
            />
          </Show>
          <img
            onClick={(e) => {
              if (isEditable) {
                e.stopPropagation();
                cycleOccasionImage();
              }
            }}
            src={
              giftDetails()?.content.images.occasionImages[currentImageIndex()]
                .imageUrl
            }
            class={`h-full w-full  rounded-[10px] object-cover transition-opacity duration-500 `}
            classList={{
              "opacity-0": imageFade(),
            }}
            alt=""
          />
        </div>
      </>
    );
  }
}

export function hubbleBrandingFooter() {
  return (
    <div class="mx-4 w-[90%] border-t border-[#79587F] py-2">
      <div class="flex items-center justify-center gap-1 text-[10px] font-medium text-[#C6A6CC]">
        Created using <img src={plutoLogo} class="h-7 w-7" alt="" />{" "}
        <span class="pt-0.5 text-[10px] font-extrabold text-white">PLUTO</span>{" "}
        by
        <img src={hubbleLogoPurple} alt="" />
      </div>
    </div>
  );
}
