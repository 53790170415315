import { editCircularIcon } from "~/assets/assets";

export const EditableField = ({
  value,
  classNames,
  onClick,
}: {
  value: () => string;
  classNames?: string;
  onClick?: () => void;
}) => (
  <div
    class="relative rounded-2xl border border-[#ffffff33] p-2"
    onClick={onClick}
  >
    <img
      src={editCircularIcon}
      class="absolute -right-[6px] -top-[6px]"
      alt="edit"
    />
    <p class={classNames}>{value()}</p>
  </div>
);
